import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { YYYYMMDD } from '../../utils/time';
import style from '../../style/components/california/CCPA.module.scss';

export const CCPA = ({ content, updatedAt, version }) => {
  return (
    <Fragment>
      <dl className={style.version}>
        <dt>Version: </dt>
        <dd>{version}</dd>
        <br />
        <dt>Last updated: </dt>
        <dd>{YYYYMMDD(updatedAt)}</dd>
      </dl>
      <p className={style.text} dangerouslySetInnerHTML={{ __html: content }} />
      <Link className={style.link} to="/california">
        Continue
      </Link>
    </Fragment>
  );
};

export default CCPA;
