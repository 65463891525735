import React, { Component } from 'react';
import { connect } from 'react-redux';
import CCPA from '../components/california/CCPA';
import { fetchCCPA } from '../actions/california';

class CCPAContainer extends Component {
  componentDidMount() {
    const { actionFetchCCPA } = this.props;
    actionFetchCCPA();

    // reset the step so that the link will take user to the Are you Resident
    window.sessionStorage.setItem('california-step', '0');
  }

  render() {
    const { content, updatedAt, version } = this.props;

    return <CCPA content={content} updatedAt={updatedAt} version={version} />;
  }
}

const mapStateToProps = (state) => ({ ...state.california });

const mapDispatchToProps = {
  actionFetchCCPA: fetchCCPA,
};

export default connect(mapStateToProps, mapDispatchToProps)(CCPAContainer);
